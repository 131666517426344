import React from 'react'
import Button from '../../components/presentationals/Button';

import './styles/LoginScreen.css';

const LoginScreen = () => {
    return (
        <main className='login-register'>
            

            <div className='login-form-container'>
                <h2>Login</h2>
                <form action="" className='form-card--login'>

                    <input type="text" name='usuario' id='usuario' placeholder='Usuario'/>
                    <input type="password" name="password" id="password" placeholder='Contraseña'/>
                    <Button title={'INGRESAR' } type={'primary'} />
                </form>

            </div>


        </main>
    )
}

export default LoginScreen
