import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import AuthRouter from "./AuthRouter";
import PersonalWebRouter from "./PersonalWebRouter";

const AppRouter = () => {
    return (
        <Router>
            <div>
                <Switch>
                    <Route path={"/auth"} component={ AuthRouter  } />
                    <Route path={"/"} component={ PersonalWebRouter } />
                </Switch>
            </div>
        </Router>
    )
}

export default AppRouter
