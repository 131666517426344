import React from 'react'
import { Route, Switch } from 'react-router-dom'
import BarNav from '../components/BarNav'
import Footer from '../components/Footer'
import Blog from '../pages/Blog'
import Contact from '../pages/Contact'
import Error404 from '../pages/Error404'
import History from '../pages/History'
import Home from '../pages/Home'
import Portfolio from '../pages/Portfolio'

const PersonalWebRouter = () => {
    return (
        <>
            <BarNav />
            <main className="main-container">
                <Switch>
                    <Route exact path="/">
                        <Home />
                    </Route>
                    <Route path="/portfolio">
                        <Portfolio />
                    </Route>
                    <Route exact path="/contact">
                        <Contact />
                    </Route>

                    <Route exact path="/blog">
                        <Blog />
                    </Route>

                    <Route exact path="/history">
                        <History />
                    </Route>

                    <Route path="*">
                        <Error404 />
                    </Route>
                </Switch>
            </main>

            <Footer />
        </>
    )
}

export default PersonalWebRouter
