import React from 'react'

const Button = ({title, type}) => {

    const styles = {
        primary:{
            backgroundColor:'#ffc107',
            border:'none',
            color:'#20202a',
            padding:'12px 16px',
            fontSize:'1.4rem',
            fontWeight:600,
            letterSpacing: '0.2rem',
            cursor: 'pointer'
        }
    }

    return (
        <button style={styles[type]}>
            {title}
        </button>
    )
}

export default Button
