import React from 'react'
import FormContact from '../components/FormContact'

import './styles/Contact.css'

const Contact = () => {
    return (
        <div className="contact-container">
            <h2 className="contact-container__title">Contact</h2>
            <FormContact />  
        </div>
         
        
    )
}

export default Contact


 